:root {
    --base-font-size: 18px;
    --transition-duration: 0.3s;
    --transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    --zoom-icon-background: rgba(25, 41, 56, 0.94);
    --zoom-icon-color: #fff;
    --lightbox-background: rgba(25, 41, 56, 0.94);
    --lightbox-z-index: 1337;
    --caption-background: hsla(0, 0%, 100%, 0.94);
    --caption-color: #192938;
    --counter-background: transparent;
    --counter-color: #fff;
    --button-background: transparent;
    --button-color: #fff;
    --loader-color: #fff;
    --slide-max-height: 85vh;
    --slide-max-width: 85vw
}

.tobii-zoom {
    border: 0;
    box-shadow: none;
    display: inline-block;
    position: relative;
    text-decoration: none
}

.tobii-zoom img {
    display: block
}

.tobii-zoom__icon {
    align-items: center;
    background-color: var(--zoom-icon-background);
    top: .44444em;
    color: var(--zoom-icon-color);
    display: flex;
    height: 1.77778em;
    justify-content: center;
    line-height: 1;
    position: absolute;
    right: .44444em;
    width: 1.77778em
}

.tobii-zoom__icon svg {
    fill: none;
    height: 1.33333em;
    pointer-events: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5;
    stroke: currentColor;
    width: 1.33333em
}

.tobii-is-open {
    overflow-y: hidden
}

.tobii {
    background-color: var(--lightbox-background);
    bottom: 0;
    box-sizing: border-box;
    contain: strict;
    font-size: var(--base-font-size);
    left: 0;
    line-height: 1.5;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: var(--lightbox-z-index)
}

.tobii[aria-hidden=true] {
    display: none
}

.tobii *, .tobii :after, .tobii :before {
    box-sizing: inherit
}

.tobii__slider {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    will-change: transform
}

.tobii__slider[aria-hidden=true] {
    display: none
}

@media screen and (prefers-reduced-motion:no-preference) {
    .tobii__slider--animate:not(.tobii__slider--is-dragging) {
        transition-duration: var(--transition-duration);
        transition-property: transform;
        transition-timing-function: var(--transition-timing-function)
    }
}

.tobii__slider--is-draggable [data-type] {
    cursor: grab
}

.tobii__slider--is-dragging [data-type] {
    cursor: grabbing
}

.tobii__slide {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%
}

.tobii__slide:not(.tobii__slide--is-active) {
    visibility: hidden
}

@media screen and (prefers-reduced-motion:no-preference) {
    .tobii__slide:not(.tobii__slide--is-active) {
        transition-duration: var(--transition-duration);
        transition-property: visibility;
        transition-timing-function: var(--transition-timing-function)
    }
}

.tobii__slide [data-type] {
    max-height: var(--slide-max-height);
    max-width: var(--slide-max-width);
    overflow: hidden;
    overflow-y: auto;
    overscroll-behavior: contain
}

.tobii__slide iframe, .tobii__slide video {
    display: block!important
}

.tobii__slide figure {
    margin: 0;
    position: relative
}

.tobii__slide figure>img {
    display: block;
    height: auto;
    max-height: var(--slide-max-height);
    max-width: var(--slide-max-width);
    width: auto
}

.tobii__slide figure>figcaption {
    background-color: var(--caption-background);
    bottom: 0;
    color: var(--caption-color);
    padding: .22222em .44444em;
    position: absolute;
    white-space: pre-wrap;
    width: 100%
}

.tobii__slide [data-type=html] video {
    cursor: auto;
    max-height: var(--slide-max-height);
    max-width: var(--slide-max-width)
}

.tobii__slide [data-type=iframe] {
    -webkit-overflow-scrolling: touch;
    transform: translateZ(0)
}

.tobii__slide [data-type=iframe] iframe {
    height: var(--slide-max-height);
    width: var(--slide-max-width)
}

.tobii__btn {
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--button-background);
    border: .05556em solid transparent;
    color: var(--button-color);
    cursor: pointer;
    font: inherit;
    line-height: 1;
    margin: 0;
    opacity: .5;
    padding: 0;
    position: absolute;
    touch-action: manipulation;
    will-change: opacity;
    z-index: 1
}

@media screen and (prefers-reduced-motion:no-preference) {
    .tobii__btn {
        transition-duration: var(--transition-duration);
        transition-property: opacity, transform;
        transition-timing-function: var(--transition-timing-function);
        will-change: opacity, transform
    }
}

.tobii__btn svg {
    fill: none;
    height: 3.33333em;
    pointer-events: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1;
    stroke: currentColor;
    width: 3.33333em
}

.tobii__btn:active, .tobii__btn:focus, .tobii__btn:hover {
    opacity: 1
}

.tobii__btn--next, .tobii__btn--previous {
    top: 50%;
    transform: translateY(-50%)
}

.tobii__btn--previous {
    left: .88889em
}

.tobii__btn--next {
    right: .88889em
}

.tobii__btn--close {
    right: .88889em;
    top: .88889em
}

.tobii__btn:disabled, .tobii__btn[aria-hidden=true] {
    display: none
}

.tobii__counter {
    background-color: var(--counter-background);
    color: var(--counter-color);
    font-size: 1.11111em;
    left: 1em;
    line-height: 1;
    position: absolute;
    top: 2.22222em;
    z-index: 1
}

.tobii__counter[aria-hidden=true] {
    display: none
}

.tobii__loader {
    display: inline-block;
    height: 5.55556em;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 5.55556em
}

.tobii__loader:before {
    animation: spin 1s infinite;
    border-radius: 100%;
    border: .22222em solid #949ba3;
    border-top: .22222em solid var(--loader-color);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1
}

@keyframes spin {
    to {
        transform: rotate(1turn)
    }
}