:root{
    --dbg: rgb(238, 246, 255);
}

  .form-control{
    border:rgba(0, 0, 0, 0.12) !important
 }

.navbar-brand-wrapper{
    z-index: 1150; 
}

.nav-link>.mdi{
    margin-right: 10px;
    color: #258AF4 !important;
}
.sidebar .nav .nav-item .nav-link{
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}
.sidebar .nav .nav-item .nav-link i.menu-icon:before{
    font-size: 25px !important;
}
*{
    font-family: 'Poppins', sans-serif !important;
}
.dbg{
    background-color: var(--dbg) !important;
    backdrop-filter: blur(10px);
}
 

.sidebar .nav .nav-item .nav-link {
    display: inline-block !important;
}
.logo{
    width: 50px;
}

#sidebar{ 
    z-index: 1144 !important;
   margin-top: -13px !important;
   border-radius: 16px; 
   position: fixed !important;  
   height:97vh !important;
   /* width: (250px) !important; */
}
 
.round-x{ 
    border-radius: 5px !important;
}
.search{
    position: absolute;
    font-size: 26px !important;
    z-index: 5 !important;
}
.mcolor{
color:#258AF4 !important
}

.badge-primary{
    background-color:var(--dbg) !important;
    border: none !important;
}
.settings{
    font-size: 23px;
color:#258AF4 !important;
cursor: pointer;
}

input,.form-control:focus,.btn:focus{
    outline: none !important;
    border: none !important;
    outline-color: none !important;
    box-shadow: none !important;
}


@media (min-width: 992px)
{

.sidebar-icon-only .sidebar .nav .nav-item .nav-link i.menu-icon {
    margin-right: 0 !important;
    margin-left: 22px !important;
}

}

.logoN{
    position: absolute;
    margin-left: -24px !important;
}
.content-wrapper{
    padding-left:8% !important;
}

.content-wrapper-pos{
  padding-left:2% !important;
}

.navbar-toggler:focus{
border: none!important;
box-shadow: none !important; 
}


.navbar-toggler{
    font-size: 30px !important;
    box-shadow: none !important; 
    
   }
 
.card-background {
    background-color: #F2F2F2;
    border-radius: 15px;
    margin: 5px;
    width: 100%;
  }

  .inventory-table{
      line-height: 10px;
  }
  p{
      font-size: 12px;
  }
  .menu-title{
      color: #258AF4 !important;
  }

  .img-rep{
      margin-bottom: 20px;
      width: 200px;
  }
  .card {
    /* margin: 10px; */
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 100%;
  }
  .card-header img {
    /* width: 120%; */
    height: 200px;
    object-fit: cover;
  }
 .center-text{
     text-align: center;
     margin-top: 10px;
     margin-bottom: 10px;
    box-shadow: 0 1px 50px rgba(0, 0, 0, 0.12);

 }
 .sales-card{
     width: 200px !important;
     /* text-align: center !important; */
     align-items: center;
     padding: 20px;
     margin: 50px;
     height: 300px;
 }

 .sales-card p{
    font-size: 14px;
    margin-top: 10px;
    height: 200px;
}
 .sales-image{
     width: 100px;
     margin-bottom: 40px;
     text-align: center;
 }
.pos-total{
    font-weight: 600;
}
.card-title{
    font-size: 15px !important;
    margin: 30px;
}

.mdi-close{
    cursor: pointer !important;
    
}

th{
    font-weight: 600 !important;
}


.input.form-control{
    border: 1px solid rgb(197, 193, 193) !important;
    border-radius: 7px;
    margin-top: 6px;

}

.main-panel { 
    width: 100% !important; 
}
.input{
    color: grey !important;
}
.rounded-x{
    border-radius: 5px !important;
}
.r4{
    border-radius: 8px !important;
}
.heads{
    border-radius: 20px !important;
    height: 78vh !important;
}
.db-search {
    border-radius: 10px;
    /* padding: 10px !important; */
    border: 1px solid grey !important;
    height: 35px !important;
    color: grey;
    font-size: 14px;
}

.db-query{
   position: absolute;
   margin-left: 3px;
   margin-top: 2px;
   color: #258AF4;
   font-size: 20px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .toggle-btn{
      margin-top: 30px !important;
  }
  
  .header-icon{
    font-size: 30px;
  }

  .header-icon-right{
    font-size: 20px;
  }
  .btn-back{
      background-color: #610606 !important;
  }

  .active{
      background-color: #2589f421;
  }

  .header-form-labels{
      margin-right: 20px;
  }

  .header-select{
      width: 80% !important;
  }

  .table-footer-button{
      float: right !important;
      text-align: right;
  }

  .pos-grid-item {
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
    float: left;
    position: relative;
    text-align: center;
    width: 140px;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .pos-card{
    background: #fff;
    border-radius: 4px;
    display: block;
    font-size: 14px;
    position: relative;
    transition: all .2s ease-in-out;
  }

  .pos-grid a[data-v-17a8978f] {
    color: #495060;
    display: inline-block;
    height: 160px;
    margin: 15px 0 0 15px;
    transition: all .1s ease-in-out;
    width: 150px;
  }

  .pos-grid-item .pos-card-body {
    border-radius: 4px;
    height: 160px;
    overflow: hidden;
    padding: 0!important;
}
  .pos-card-body{
    padding: 16px;
  }

  .pos-grid-item .pos-grid-item-name {
    align-content: center;
    background: #fff;
    border-radius: 0 0 4px 4px;
    bottom: 0;
    display: flex;
    justify-content: center;
    max-height: 80px;
    min-height: 60px;
    padding: 10px;
    position: absolute;
    text-align: center;
    width: 150px;
}
  
  .label {
    /* display: inline-flex; */
    /* align-items: center; */
    cursor: pointer;
    /* margin-top: 20px; */
  }
  
  .label-text {
    margin-left: 8px;
  }
  
  .toggle {
    isolation: isolate;
    position: relative;
    height: 24px;
    width: 48px;
    margin-top: 20px;
    border-radius: 15px;
    background: #d6d6d6;
    overflow: hidden;
  }
  
  .toggle-inner {
    z-index: 2;
    position: absolute;
    top: 1px;
    left: 1px;
    height: 22px;
    width: 46px;
    border-radius: 15px;
    overflow: hidden;
  }
  
  .active-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 200%;
    background: #003dda;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.05s linear 0.17s;
  }
  
  .toggle-state {
    display: none;
    margin-top: 20px;
  }
  
  .indicator {
    height: 100%;
    width: 200%;
    background: white;
    border-radius: 13px;
    transform: translate3d(-75%, 0, 0);
    transition: transform 0.35s cubic-bezier(0.85, 0.05, 0.18, 1.35);
  }
  
  .toggle-state:checked ~ .active-bg {
     transform: translate3d(-50%, 0, 0);
  }
  
  .toggle-state:checked ~ .toggle-inner .indicator {
     transform: translate3d(25%, 0, 0);
  }
  .pos-order-actions {
    bottom: 0;
    display: flex;
    position: absolute;
    width: 100%;
}

.below-order-items {
    background-color: #f5f5f6;
    height: 40px;
}
.pos-order-actions .ivu-btn-group {
  display: flex;
  width: 100%;
}

.below-order-items .order-total {
  border-bottom: 1px solid #dddee1;
  border-top: 1px solid #dddee1;
  line-height: 38px;
}


.above-order-items {
    background-color: #f5f5f6;
    display: block;
    height: 65px;
}

.above-order-items .order-sub-details {
    height: 25px;
    line-height: 25px;
}

.above-order-items .order-info, .above-order-items .order-sub-details {
  border-bottom: 1px solid #dddee1;
  display: flex;
  padding: 0 10px;
}
.pos-order {
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  height: calc(100vh - 50px);
  position: relative;
}
.pos-order-items .order-items .header {
  align-items: center;
  background: #515a6e;
  border: none;
  border-bottom: 1px solid #dddee1;
  color: #fff;
  display: flex;
  font-weight: 700;
  justify-content: center;
  padding: 6px 10px;
}

.order-items .header {
  align-items: center;
  background: #ddd;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 0 0 0 0;
  color: #515a6e;
  display: flex;
  font-weight: 700;
  justify-content: center;
  padding: 6px 10px;
}

.below-order-items .order-total .total {
  font-weight: 700;
  padding: 0 10px;
}


.pos-order-actions {
    bottom: 0;
    display: flex;
    position: absolute;
    width: 100%;
}
.pos-order-actions .ivu-btn-group {
  display: flex;
  width: 100%;
}

.pos-order-items .order-items .order-item:nth-child(2n) {
  background: #f8f8f9;
}

.pos-order-items .order-items .order-item {
  border-bottom: 1px solid #dddee1;
  padding: 5px 10px;
}